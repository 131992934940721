import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useAvailability } from '../../../hooks/useAvailability';
import { DataLayerEvents } from '../../../interfaces/tracking';
import { pushAvailability } from '../../../utils/dataLayer/availability';
import { getExpansionAvailabilityStatus } from '../../../utils/dataLayer/getExpansionStatus';
import { checkMandantType, getEnv, isNC } from '@ncs-frontend-monorepo/utils';
import {
  getMaxDownloads,
  getTechnologyDownloads,
  hasTechnologies,
} from '../../../utils/promotion';
import {
  AvailabilityProvider,
  AvailabilityTypes,
} from '../../AvailabilityCheck/Context';
import { AvailabilityForm } from '../../AvailabilityCheck/Views/Form';
import { useOrderContext } from '../../OrderContext';
import { ExpansionStatus, Fallback } from '@ncs-frontend-monorepo/availability';
import { Technology } from '@ncs-frontend-monorepo/availability';

const AreaPlanned = dynamic(() => import('./AreaPlanned'));
const Error = dynamic(() => import('./Error'));
const HomesConnected = dynamic(() => import('./HomesConnected'));
const HomesPassed = dynamic(() => import('./HomesPassed'));
const HomesPrepared = dynamic(() => import('./HomesPrepared'));
const NotPlanned = dynamic(() => import('./NotPlanned'));
const OutOfArea = dynamic(() => import('./OutOfArea'));
const PreMarketing = dynamic(() => import('./PreMarketing'));
const UnderConstruction = dynamic(() => import('./UnderConstruction'));
const Presale = dynamic(() => import('./Presale'));
const Cable = dynamic(() => import('./Cable'));
const FTTB = dynamic(() => import('./FTTB'));
const FallbackModal = dynamic(() => import('./Fallback'));

export interface ContentProviderProps {
  e2e: string;
  availabilityType?: AvailabilityTypes;
  requestedDownload?: number;
}
const ContentProvider: React.FC<ContentProviderProps> = ({
  e2e,
  availabilityType = AvailabilityTypes.Expansion,
  requestedDownload,
}) => {
  const [view, setView] = useState<ExpansionStatus>(ExpansionStatus.UNKNOWN);
  const {
    clearOrder,
    address,
    maxAvailableDownload,
    plannedAvailability,
    setAvailability,
    setFTTHPresalesInformation,
  } = useOrderContext();
  const {
    availabilityInfo,
    getAlternativeProductAvailability,
    handleAvailabilityCheck,
  } = useAvailability();
  const router = useRouter();
  const {
    eventOpen,
    eventResult,
    eventClose,
    eventCheck,
    eventChangeAddress,
    getAvailabilityStatus,
  } = pushAvailability();

  const mandantType = checkMandantType(getEnv().SITE, address?.mandant);

  const resetAddress = () => {
    eventChangeAddress({
      event: DataLayerEvents.ChangeAddress,
      status: getAvailabilityStatus(availabilityInfo, requestedDownload),
      checkSource: availabilityType,
      zipCode: address.zipCode,
      maxDownload: maxAvailableDownload,
    });
    setView(ExpansionStatus.UNKNOWN);
    clearOrder();
  };

  e2e = `${view.toLowerCase()}-${e2e}`;

  const availabilityChecked = !!(
    address &&
    (maxAvailableDownload || plannedAvailability)
  );

  useEffect(() => {
    eventOpen({
      event: DataLayerEvents.Open,
      checkStatus: availabilityChecked ? 'bereits-geprueft' : 'nicht-geprueft',
      checkSource: availabilityType,
    });

    return () => {
      eventClose({
        event: DataLayerEvents.Close,
        checkSource: availabilityType,
      });
    };
  }, []);

  const handleCheck = useCallback(async (v) => {
    eventCheck({
      event: DataLayerEvents.Check,
      checkSource: AvailabilityTypes.Expansion,
      zipCode: v.zipCode,
    });
    await handleAvailabilityCheck(
      {
        street: v.street,
        houseNumber: v.houseNumber,
        zipCode: v.zipCode,
        city: v.city,
      },
      {
        manualChecked: true,
      },
      true,
    );
  }, []);

  useEffect(() => {
    if (availabilityInfo.isChecked) {
      const {
        address,
        objectInformation,
        promotions: { availablePromotions, presalePromotion, tvPromotions },
      } = availabilityInfo;

      //redirect to suitable mandant
      if (!!address?.mandant && address?.mandant !== (isNC() ? 'NC' : 'NA')) {
        if (address?.mandant === 'NA') {
          router.push(`https://www.netaachen.de/${router.asPath}`);
        } else {
          router.push(`https://www.netcologne.de/${router.asPath}`);
        }
        return;
      }

      //show error on
      if (!availabilityInfo.objectInformation) {
        setView(ExpansionStatus.ERROR);
        return;
      }

      const isFallback =
        availabilityInfo.promotions.hasFallback === Fallback.TECHNOLOGY;

      if (isFallback) {
        setView(ExpansionStatus.FALLBACK);
        return;
      }

      // set objectinformation to order context
      setFTTHPresalesInformation(
        availabilityInfo.objectInformation,
        getAlternativeProductAvailability([
          ...(availablePromotions ? availablePromotions : []),
          ...(presalePromotion ? [presalePromotion] : []),
        ]),
      );

      if (presalePromotion) {
        const { maxAvailableDownload, plannedAvailability } = getMaxDownloads({
          availablePromotions,
          presalePromotion,
        });
        setAvailability(
          maxAvailableDownload,
          plannedAvailability,
          tvPromotions,
          getTechnologyDownloads(availablePromotions, presalePromotion),
        );
        setView(ExpansionStatus.PRE_SALE);
        return;
      }

      // CABLE-technology
      if (
        hasTechnologies({
          technologies: [Technology.CABLE],
          availablePromotions,
          presalePromotion,
        })
      ) {
        const { maxAvailableDownload, plannedAvailability } = getMaxDownloads({
          availablePromotions,
          presalePromotion,
        });
        setAvailability(
          maxAvailableDownload,
          plannedAvailability,
          tvPromotions,
          getTechnologyDownloads(availablePromotions, presalePromotion),
        );
        setView(ExpansionStatus.CABLE);
        return;
      }

      // special handling for GFAST and FTTH
      if (
        hasTechnologies({
          technologies: [
            Technology.GFAST,
            Technology.FTTH,
            Technology.FTTH_BSA_L2,
            Technology.FTTH_BSA_L2_DG,
          ],
          availablePromotions,
          presalePromotion,
        })
      ) {
        const { maxAvailableDownload, plannedAvailability } = getMaxDownloads({
          availablePromotions,
          presalePromotion,
        });

        setAvailability(
          maxAvailableDownload,
          plannedAvailability,
          tvPromotions,
          getTechnologyDownloads(availablePromotions, presalePromotion),
        );
        setView(ExpansionStatus.HOMES_CONNECTED);
        return;
      }

      // special handling for FTTB
      if (
        hasTechnologies({
          technologies: [Technology.VDSL2_FTTB],
          availablePromotions,
        })
      ) {
        const { maxAvailableDownload } = getMaxDownloads({
          availablePromotions,
          presalePromotion,
        });

        setAvailability(
          maxAvailableDownload,
          plannedAvailability,
          tvPromotions,
          getTechnologyDownloads(availablePromotions, presalePromotion),
        );
        setView(ExpansionStatus.FTTB);
        return;
      }

      if (objectInformation.technicalPromotionBandwidth > 0) {
        setAvailability(objectInformation.technicalPromotionBandwidth);
      }

      setView(objectInformation.status);
    }
  }, [availabilityInfo]);

  useEffect(() => {
    if (view !== ExpansionStatus.UNKNOWN) {
      eventResult({
        event:
          mandantType !== 'match'
            ? DataLayerEvents.ResultMandantMismatch
            : DataLayerEvents.Result,
        status: getExpansionAvailabilityStatus(view),
        expansionStatus: view || null,
        checkSource: AvailabilityTypes.Expansion,
        checkStatus: availabilityChecked
          ? 'bereits-geprueft'
          : 'nicht-geprueft',
        zipCode: address.zipCode,
        maxDownload: maxAvailableDownload,
        presaleAvailability: !!plannedAvailability,
        ...(plannedAvailability && {
          plannedAvailabilityDate:
            plannedAvailability.plannedAvailabilityDateDescription,
        }),
      });
    }
  }, [view, mandantType]);

  return (
    <AvailabilityProvider
      availabilityType={AvailabilityTypes.Expansion}
      availabilityInfo={availabilityInfo}
    >
      {view === ExpansionStatus.UNKNOWN && (
        <AvailabilityForm
          headline="Ausbaustatus prüfen"
          subline="Prüfe jetzt, ob sich deine Adresse in unserem Glasfaser-Ausbaugebiet befindet."
          onSubmit={handleCheck}
        />
      )}
      {view === ExpansionStatus.NOT_PLANNED && (
        <NotPlanned e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.AREA_PLANNED && (
        <AreaPlanned e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.PRE_MARKETING && (
        <PreMarketing e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.UNDER_CONSTRUCTION && (
        <UnderConstruction e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {[
        ExpansionStatus.HOMES_PASSED,
        ExpansionStatus.HOMES_PASSED_PLUS,
      ].includes(view) && (
        <HomesPassed e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {[ExpansionStatus.HOMES_PREPARED, ExpansionStatus.HOMES_READY].includes(
        view,
      ) && <HomesPrepared e2e={e2e} onChangeAddressClick={resetAddress} />}
      {[ExpansionStatus.NETWORK_NC, ExpansionStatus.HOMES_CONNECTED].includes(
        view,
      ) && <HomesConnected e2e={e2e} onChangeAddressClick={resetAddress} />}
      {view === ExpansionStatus.OUT_OF_AREA && (
        <OutOfArea e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.PRE_SALE && (
        <Presale e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.CABLE && (
        <Cable e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.FTTB && (
        <FTTB e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.ERROR && (
        <Error e2e={e2e} onChangeAddressClick={resetAddress} />
      )}
      {view === ExpansionStatus.FALLBACK && <FallbackModal e2e={e2e} />}
    </AvailabilityProvider>
  );
};

export { ContentProvider };
