import { Banner, BannerProps } from '@ncs-frontend-monorepo/content-library';
import React from 'react';
import { useModalContext } from '../ModalContext';
import { ContentProvider } from './Modals';

export type ExpansionCheckProps = BannerProps & {
  button: string;
  e2e: string;
};

const ExpansionCheck: React.FC<ExpansionCheckProps> = ({
  backgroundColor,
  backgroundColorMobile,
  buttonType,
  color,
  colorMobile,
  headline,
  headlineElement,
  image,
  layout,
  text,
  button,
  e2e,
}) => {
  const { handleModal } = useModalContext();

  return (
    <Banner
      backgroundColor={backgroundColor}
      backgroundColorMobile={backgroundColorMobile}
      buttonType={buttonType}
      color={color}
      colorMobile={colorMobile}
      headline={headline}
      headlineElement={headlineElement}
      image={image}
      layout={layout}
      text={text}
      button={{
        text: button,
        e2e: `expansion-check-open-${e2e}`,
        onClick: () => {
          handleModal({
            e2e: `modal-expansion-check-${e2e}`,
            content: <ContentProvider e2e={e2e} />,
          });
        },
      }}
    />
  );
};

export { ExpansionCheck };
