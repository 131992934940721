import { ExpansionStatus } from '@ncs-frontend-monorepo/availability';
import { AvailabilityStatus } from '../../interfaces/tracking';

const statusAvailable = [
  ExpansionStatus.NOT_PLANNED,
  ExpansionStatus.HOMES_CONNECTED,
  ExpansionStatus.NETWORK_NC,
  ExpansionStatus.FTTB,
];

const statusAlternative = [
  ExpansionStatus.HOMES_PASSED,
  ExpansionStatus.HOMES_PASSED_PLUS,
  ExpansionStatus.CABLE,
];

const statusAvailablePresale = [
  ExpansionStatus.AREA_PLANNED,
  ExpansionStatus.PRE_MARKETING,
  ExpansionStatus.UNDER_CONSTRUCTION,
  ExpansionStatus.HOMES_PREPARED,
  ExpansionStatus.HOMES_READY,
  ExpansionStatus.PRE_SALE,
];
export const getExpansionAvailabilityStatus = (
  expansionStatus: ExpansionStatus,
): AvailabilityStatus => {
  switch (true) {
    case statusAvailable.includes(expansionStatus):
      return AvailabilityStatus.Available;
    case statusAlternative.includes(expansionStatus):
      return AvailabilityStatus.Alternative;
    case statusAvailablePresale.includes(expansionStatus):
      return AvailabilityStatus.AvailablePresale;
    default:
      return AvailabilityStatus.NotAvailable;
  }
};
